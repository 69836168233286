.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: fit-content;
  }
  
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .Collapsible {
    border: 1px solid burlywood;
    margin-bottom: 20px;
    padding: 10px;
  }
  .dropdown-menu-xs.dropdown-menu-right.dropdown-menu.show {
    right: 0px !important;
  }

  .Collapsible span {
    text-transform: uppercase;
    padding: 10px;
    background-color: antiquewhite;
    font-weight: bold;
  }

  .account-transfer-form {
    background-color: #ffffff;
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 10px;
    width: 100%;
  }

  .reset_password {
    cursor: pointer;
  }

  .reset_password span:hover {
    font-weight: bold;
  }

  .loading_div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loading_icon {
    text-align: center;
    width: 100%;
  }

  .requiredField {
    border: 1px solid #005de9;
    border-radius: 10px;
  }

  .mandatoryField {
    border: 1px solid #e90000;
    border-radius: 10px;
  }